import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import theme from "theme";

const Banner = styled.div`
  background-color: #fff;
  position: fixed;
  max-width: 785px;
  right: 2%;
  bottom: 20px;
  border-radius: 5px;
  z-index: 2147483646;
  box-shadow: ${theme.shadows.shadow};

  ${theme.screens.mdScreen} {
    width: calc(100% - 30px);
    right: 15px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 25px 20px;

  ${theme.screens.mdScreen} {
    justify-content: center;
  }

  .readmore {
    font-size: 14px;
    color: #17204f;
    text-decoration: none;
    padding-left: 40px;

    ${theme.screens.mdScreen} {
      margin-top: 20px;
    }
    @media screen and (min-width: 345px) and (max-width: 385px) {
      padding-left: 10px;
    }
    @media screen and (max-width: 345px) {
      padding-left: 0;
    }
  }
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: normal;
  max-width: 380px;

  ${theme.screens.mdScreen} {
    max-width: 100%;
  }
`;

const Button = styled.button`
  border: 1px solid #cccccc;
  box-shadow: none;

  ${theme.screens.mdScreen} {
    margin-top: 20px;
  }
`;

const PolicyLink = styled(Link)`
  text-decoration: underline;
  color: ${theme.colors.textColor};
  transition: all 0.25s ease-in-out;

  ${theme.mixins.onEvent} {
    color: ${theme.colors.textColor};
    opacity: 0.7;
    text-decoration: underline;
  }
`;

// const Icon = styled.img`
//   margin-left: 30px;
//   cursor: pointer;

//   ${theme.screens.mdScreen} {
//     margin-top: 20px;
//   }
// `;

const CookieBanner = ({ onClick }) => {
  return (
    <Banner>
      <ContentContainer>
        <Text>
          Our site uses cookies to personalise content and analyse traffic. For
          more information read our{" "}
          <PolicyLink to="/cookies-policy/">Cookie Policy</PolicyLink>.
        </Text>
        <Button className="btn btn--white" type="button" onClick={onClick}>
          I Accept
        </Button>
        <Link to="/cookies-policy/" className="readmore">
          Read More
        </Link>
      </ContentContainer>
    </Banner>
  );
};

export default CookieBanner;
