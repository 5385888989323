const theme = {
  colors: {
    white: "#fff",
    textColor: "#17204F",
    pink: "#CA3B6C",
    violet: "#673180",
    red: "#D03D6A",
    blue: "#17204F",
    light: "#F8FAFF",
    orange: "#DD716B",
    yellow: "#EDA35A",
    gray: "#E4E4E4",
    lavenda: "#A13676",
  },

  shadows: {
    shadow: "0px 0px 30px 2px #17204F1A",
    shadowPink: "0 5px 15px 2px rgba(208, 61, 106, 0.35)",
  },

  screens: {
    smScreen: `@media (max-width: 575px)`,
    mdScreen: `@media (max-width: 768px)`,
    lgScreen: `@media (max-width: 991px)`,
    xlScreen: `@media (max-width: 1200px)`,
    xxlScreen: `@media (max-width: 1600px)`,
  },

  mixins: {
    onEvent: `&:hover, &:focus, &:active`,
  },
};

export default theme;
