import React, { useEffect, useState } from "react";
import { CookiesProvider, withCookies } from "react-cookie";

import Header from "./Header";
import Footer from "./Footer";
import CookieBanner from "./CookieBanner";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "styles/app.scss";

const Layout = ({ children, cookies, logo_variant }) => {
  const [cookieBaner, setCookieBaner] = useState("");

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get("prodinityCookie");
    !isAcceptedCoookie && setCookieBaner(true);
  }, [cookies]);

  const acceptCookieBaner = () => {
    const promiseSetCookie = new Promise((resolve) =>
      resolve(cookies.set("prodinityCookie", "active", { path: "/" }))
    );
    promiseSetCookie.then(() => {
      setCookieBaner(false);
    });
  };
  return (
    <CookiesProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6LeetSkdAAAAAHDMkyrVskSv2DaSpBjnS7Yd2z-s">
        <Header logo_variant={logo_variant} />
        {cookieBaner && <CookieBanner onClick={acceptCookieBaner} />}
        <main>{children}</main>
        <Footer />
        {/* <div className={`cookie-baner ${cookieBaner ? " open" : ""}`}>
            <div className="cookie-baner__content">
              <img src={CookieIcon} alt="" className="cookie-baner__img" />
              <p className="cookie-baner__text">
                Aby zoptymalizować naszą stronę internetową i stale ją ulepszać,
                używamy plików cookie
              </p>
              <div className="cookies-banner__buttons-container">
                <button type="button" onClick={acceptCookieBaner}>
                  Zgadzam się
                </button>
                <Link to="/polityka-cookies/">Więcej informacji</Link>
              </div>
            </div>
          </div> */}
      </GoogleReCaptchaProvider>
    </CookiesProvider>
  );
};

export default withCookies(Layout);
