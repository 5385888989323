import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import theme from "../../theme";
import Arrow from "assets/icons/header-arrow.svg";
import Hamburger from "../Hamburger";

const Nav = styled.nav`
  @media (max-width: 1599px) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    transition: all 0.25s ease-in-out;
  }

  @media screen and (min-width: 991px) and (max-width: 1599px) {
    justify-content: flex-end;
    margin-right: 50px;
  }

  ${theme.screens.xlScreen} {
    margin-right: 40px;
  }

  ${theme.screens.lgScreen} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    overflow-x: hidden;
    padding: 70px 0;
    background-color: #fff;
    transform: ${({ isMenuOpen }) =>
      isMenuOpen ? `translate(0, 0)` : `translate(100%, 0)`};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1560px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 1200px;
  }

  @media (max-width: 1200px) {
    max-width: 970px;
  }

  ${theme.screens.lgScreen} {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  ${theme.screens.smScreen} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Logo = styled.img`
  filter: brightness(1) invert(0);
  transition: filter 0.25s;
  width: 100%;
  max-width: 250px;
  min-width: 250px;

  ${theme.screens.xlScreen} {
    max-height: 30px;
    max-height: 40px;
    min-width: 190px;
  }
`;

const HeaderBelt = styled.header`
  background-color: ${({ isOpened }) =>
    isOpened
      ? `
      ${theme.colors.blue}
    `
      : `#fff`};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 35px 0 31px;
  z-index: 900;
  border-bottom: 1px solid rgb(239, 239, 239);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    ${({ color }) =>
      color === "green"
        ? "background: linear-gradient(90deg,rgba(252, 238, 33, 1) 0%,rgba(140, 198, 63, 1) 50%, rgba(0, 104, 55, 1) 100%);"
        : color === "blue"
        ? "background: linear-gradient(90deg,rgba(0, 255, 255, 1) 0%,rgba(119, 90, 149, 1) 50%, rgba(25, 30, 111, 1) 100%);"
        : "background: linear-gradient(90deg,rgba(247, 160, 86, 1) 0%,rgba(228, 41, 120, 1) 50%, rgba(91, 5, 171, 1) 100%);"};
  }

  ${theme.screens.lgScreen} {
    background-color: #fff !important;
  }

  ${({ isOpened }) =>
    isOpened &&
    `
    ${NavLink}, ${NavItem} {
      color: #fff;
      ${theme.mixins.onEvent} {
        color: #fff;
      }
      ${theme.screens.lgScreen} {
        color: ${theme.colors.blue}!important;
      }
    }
    ${Logo} {
      filter: brightness(0) invert(1);

      ${theme.screens.lgScreen} {
        filter: brightness(1) invert(0)!important;
      }
    }
  `}
`;

const NavLink = styled(Link)`
  font-size: 16px;
  margin-right: 90px;
  position: relative;
  @media (max-width: 1599px) {
    font-size: 14px;
    margin-right: 60px;
  }

  ${theme.screens.xlScreen} {
    margin-right: 30px;
  }

  ${theme.screens.lgScreen} {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    content: "";
    height: 3px;
    width: 44px;
    background-color: ${({ color }) =>
      color === "green"
        ? "#009245"
        : color === "blue"
        ? "#0071BC"
        : theme.colors.pink};
    bottom: -34px;
    left: 0;
    opacity: 0;
    transition: all 0.25s;
    ${theme.screens.lgScreen} {
      display: none;
    }
  }

  ${theme.mixins.onEvent} {
    color: ${theme.colors.textColor};
    &:after {
      opacity: 1;
    }
  }

  &.active {
    &:after {
      opacity: 1;
    }
  }
`;

const NavItem = styled.div`
  font-size: 16px;
  margin-right: 90px;
  position: relative;
  display: inline;
  cursor: pointer;

  @media (max-width: 1599px) {
    font-size: 14px;
    margin-right: 60px;
  }

  ${theme.screens.xlScreen} {
    margin-right: 30px;
  }

  ${theme.screens.lgScreen} {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  &:after {
    position: absolute;
    content: "";
    height: 3px;
    width: 44px;
    background-color: ${theme.colors.pink};
    bottom: -34px;
    left: 0;
    opacity: 0;
    transition: all 0.25s;
    ${theme.screens.lgScreen} {
      display: none;
    }
  }

  ${theme.mixins.onEvent} {
    color: ${theme.colors.textColor};
    &:after {
      opacity: 1;
    }
  }
`;

const LangSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  //TODO: make LangSwitch responsive (move to hamburger menu)
  ${theme.screens.lgScreen} {
    display: none;
  }
`;

const LangArrow = styled.img`
  margin-left: 8px;
`;

const SubmenuContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 105px;
  background-color: ${theme.colors.blue};
  padding: 40px 0 40px 530px;

  @media (max-width: 1680px) {
    padding: 40px 0 40px 415px;
  }

  @media (max-width: 1600px) {
    padding: 40px 0 40px 523px;
  }

  @media (max-width: 1440px) {
    padding: 40px 0;
  }

  ${theme.screens.xlScreen} {
    top: 91px;
  }

  ${theme.screens.lgScreen} {
    display: none !important;
  }

  .container {
    display: flex;
    padding-left: 352px;

    @media (max-width: 1440px) {
      padding-left: 264px;
    }
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.screens.lgScreen} {
    display: none !important;
  }
`;

const SubMenuItem = styled.div`
  font-size: 16px;
  color: #fff;
  opacity: 0.35;
  margin-bottom: 30px;
  transition: all 0.25s;
  cursor: pointer;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${theme.mixins.onEvent} {
    opacity: 1;
  }

  ${({ arrow }) =>
    arrow &&
    `
    &:after {
    content: "";
    position: absolute;
    right: 70px;
    top: 8px;
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    background-image: url(${Arrow});
    transition: all .25s ease-in-out;
  }
  ${theme.mixins.onEvent} {
    &:after {
      transform: translateX(4px);
    }
  }
  `}
`;

const RightSubMenu = styled(SubMenu)`
  padding-left: 65px;
`;

const RightSubMenuItem = styled(Link)`
  font-size: 16px;
  color: #fff;
  opacity: 0.35;
  margin-bottom: 30px;
  transition: opacity 0.25;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${theme.mixins.onEvent} {
    opacity: 1;
    color: #fff;
  }
`;

const LeftCol = styled.div`
  width: 375px;
  height: 100%;
`;

const RightCol = styled.div`
  width: 375px;
  height: 100%;
`;

const MobileNavCloseBtn = styled.button`
  font-size: 24px;
  position: absolute;
  left: 30px;
  top: 30px;
  color: ${theme.colors.blue};
  font-weight: 700;
  background: transparent;
  border: none;
  appearance: none;
  outline: none;
  z-index: 800;
`;

const MobileNav = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 800;
  background-color: #fff;
  transition: all 0.25s;
  /* transform: translate(-100%, 0); */

  ${theme.screens.lgScreen} {
    z-index: ${({ opened }) => (opened ? 100 : -999)};
    display: flex;
    transform: ${({ opened }) =>
      opened ? "translate(0, 0)" : "translate(100%, 0)"};
  }

  ${NavLink} {
    font-size: 18px;
  }
`;

const Header = ({ logo_variant }) => {
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const subMenuItems = {
    SOLUTIONS: {
      key: "SOLUTIONS",
      items: [
        {
          label: "Solutions",
          onClick: (e) => navigate("/solutions/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Business Insights",
          onClick: (e) => navigate("/business-insights/"),
          onMouseOver: (e) => setSelectedSubMenu("BUSINESSINSIGHTS"),
          arrow: true,
        },
        {
          label: "Data Pooling",
          onClick: (e) => navigate("/data-pooling/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Cyber Security",
          onClick: (e) => navigate("/cyber-security/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Budgeting",
          onClick: (e) => navigate("/budgeting/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Forecasting",
          onClick: (e) => navigate("/forecasting/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Reporting",
          onClick: (e) => navigate("/reporting/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Analysis",
          onClick: (e) => navigate("/analysis/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
        {
          label: "Other services",
          onClick: (e) => navigate("/other-services/"),
          onMouseOver: (e) => setSelectedSubMenu(""),
        },
      ],
    },
    COMPANY: {
      key: "COMPANY",
      items: [
        {
          label: "History",
          onClick: (e) => navigate("/history/"),
        },
        {
          label: "Mission & Vision",
          onClick: (e) => navigate("/mission-vision/"),
        },
        {
          label: "Our team",
          onClick: (e) => navigate("/our-team/"),
        },
        {
          label: "Careers",
          onClick: (e) => navigate("/careers/"),
        },
        {
          label: "Media and press materials",
          onClick: (e) => navigate("/media-and-press-materials/"),
        },
      ],
    },
    NEWS: {
      key: "NEWS",
      items: [
        { label: "News", onClick: (e) => navigate("/news/") },
        { label: "Blog", onClick: (e) => navigate("/blog/") },
      ],
    },
  };

  const rightSubMenuItems = {
    BUSINESSINSIGHTS: {
      key: "BUSINESSINSIGHTS",
      items: [
        {
          label: "Insurance Intermediaries",
          link: "/insurance-intermediaries/",
        },
      ],
    },
  };

  const logo_variant_render = () => {
    switch (logo_variant) {
      case "green":
        return require("assets/images/logo-color-green.svg");
      case "blue":
        return require("assets/images/logo-color-blue.svg");
      default:
        return require("assets/images/logo-color-new.svg");
    }
  };

  return (
    <HeaderBelt
      color={logo_variant}
      className={`header`}
      isOpened={selectedMenu !== ""}
      onMouseLeave={(e) => {
        setSelectedMenu("");
        setSelectedSubMenu("");
      }}
    >
      <FlexContainer>
        <Link to="/">
          <Logo src={logo_variant_render().default} alt="" />
        </Link>
        <Nav isMenuOpen={isMenuOpen}>
          <NavItem
            onMouseOver={(e) => {
              setSelectedMenu("SOLUTIONS");
              setSelectedSubMenu("");
            }}
          >
            Solutions
          </NavItem>
          <MobileNav opened={selectedMenu === "SOLUTIONS"}>
            <MobileNavCloseBtn
              type="button"
              onClick={(e) => setSelectedMenu("")}
            >
              &#10229;
            </MobileNavCloseBtn>
            <NavLink color={logo_variant} to="/solutions/">
              Solutions
            </NavLink>
            <NavLink to="/business-insights/">Business Insights</NavLink>
            <NavLink to="/insurance-intermediaries/">
              Insurance Intermediaries
            </NavLink>
            <NavLink to="/data-pooling/">Data Pooling</NavLink>
            <NavLink to="/cyber-security/">Cyber Security</NavLink>
            <NavLink to="/budgeting/">Budgeting</NavLink>
            <NavLink to="/forecasting/">Forecasting</NavLink>
            <NavLink to="/reporting/">Reporting</NavLink>
            <NavLink to="/analysis/">Analysis</NavLink>
            <NavLink to="/other-services/">Other services</NavLink>
          </MobileNav>
          <NavLink
            to="/benefits/"
            onClick={(e) => setSelectedMenu("")}
            onMouseOver={(e) => setSelectedMenu("")}
            activeClassName="active"
            color={logo_variant}
          >
            Benefits
          </NavLink>
          <NavLink
            to="/industries/"
            onClick={(e) => setSelectedMenu("")}
            onMouseOver={(e) => setSelectedMenu("")}
            activeClassName="active"
            color={logo_variant}
          >
            Industries
          </NavLink>
          {/* <NavLink
            to="/case-studies/"
            onClick={(e) => setSelectedMenu("")}
            onMouseOver={(e) => setSelectedMenu("")}
            activeClassName="active"
            color={logo_variant}
          >
            Case studies
          </NavLink> */}
          <NavItem
            onMouseOver={(e) => {
              setSelectedMenu("COMPANY");
              setSelectedSubMenu("");
            }}
          >
            Company
          </NavItem>
          <MobileNav opened={selectedMenu === "COMPANY"}>
            <MobileNavCloseBtn
              type="button"
              onClick={(e) => setSelectedMenu("")}
            >
              &#10229;
            </MobileNavCloseBtn>
            <NavLink to="/history/">History</NavLink>
            <NavLink to="/mission-vision/">Mission & Vision</NavLink>
            <NavLink to="/our-team/">Our team</NavLink>
            <NavLink to="/careers/">Career</NavLink>
          </MobileNav>
          <NavItem
            onMouseOver={(e) => {
              setSelectedMenu("NEWS");
            }}
          >
            News
          </NavItem>
          <MobileNav opened={selectedMenu === "NEWS"}>
            <MobileNavCloseBtn
              type="button"
              onClick={(e) => setSelectedMenu("")}
            >
              &#10229;
            </MobileNavCloseBtn>
            <NavLink to="/news/" activeClassName="active">
              News
            </NavLink>
            <NavLink to="/blog/" activeClassName="active">
              Blog
            </NavLink>
          </MobileNav>

          <NavLink
            to="/contact/"
            onClick={(e) => setSelectedMenu("")}
            onMouseOver={(e) => setSelectedMenu("")}
            activeClassName="active"
            color={logo_variant}
          >
            Contact
          </NavLink>
        </Nav>
        {/* <LangSwitch>
          EN
          <LangArrow
            src={require("assets/icons/dropdown-arrow.svg").default}
            alt=""
          />
        </LangSwitch> */}
        <Hamburger onClick={(e) => setIsMenuOpen(!isMenuOpen)} />
      </FlexContainer>
      {selectedMenu !== "" && (
        <SubmenuContainer>
          <div className="container">
            <LeftCol style={{ borderRight: "1px solid #364077" }}>
              <SubMenu>
                {Object.values(subMenuItems[selectedMenu].items).map(
                  (item, index) => {
                    return (
                      <SubMenuItem
                        key={index}
                        arrow={item.arrow}
                        onClick={item.onClick}
                        onMouseOver={item.onMouseOver}
                        onMouseOut={item.onMouseOut}
                      >
                        {item.label}
                      </SubMenuItem>
                    );
                  }
                )}
              </SubMenu>
            </LeftCol>
            <RightCol>
              <RightSubMenu>
                {selectedSubMenu !== "" &&
                  Object.values(rightSubMenuItems[selectedSubMenu].items).map(
                    (item, index) => {
                      return (
                        <RightSubMenuItem key={index} to={item.link}>
                          {item.label}
                        </RightSubMenuItem>
                      );
                    }
                  )}
              </RightSubMenu>
            </RightCol>
          </div>
        </SubmenuContainer>
      )}
    </HeaderBelt>
  );
};

export default Header;
