import styled from "styled-components";
import theme from "../../theme";

import React from "react";
import { useState, useEffect } from "react";

const IconButton = styled.button`
  position: fixed;
  left: 50px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${theme.colors.white};
  transition: all 0.25s ease-in-out;
  border: 1px solid ${theme.colors.gray};
  z-index: 100;

  ${theme.screens.smScreen} {
    left: 20px;
    bottom: 20px;
    width: 70px;
    height: 70px;
  }

  @media (max-width: 400px) {
    left: 15px;
    bottom: 15px;
    width: 40px;
    height: 40px;
  }

  ${theme.mixins.onEvent} {
    border: 1px solid ${theme.colors.pink};
    box-shadow: ${theme.shadows.shadow};
    transform: translateY(-3px);
  }
`;

const ArrowIcon = styled.img`
  display: block;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  transform: rotate(-90deg);
`;

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <IconButton onClick={handleClick} aria-label="to top" component="span">
          <ArrowIcon
            src={require("../../assets/icons/btn-arrow-pink.svg").default}
            alt=""
          />
        </IconButton>
      )}
    </div>
  );
};
export default ScrollToTop;
