import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import theme from "../../theme";
import ScrollToTop from "../ScrollToTop";

const FooterMain = styled.footer`
  background-color: ${theme.colors.blue};
  padding: 50px 0 0;
`;

const FooterCol = styled.div`
  ${theme.screens.lgScreen} {
    &:nth-of-type(n + 6) {
      margin-top: 50px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: -25px;
        left: 0;
        background-color: #888da7;
      }
    }
  }
  ${theme.screens.mdScreen} {
    &:nth-of-type(n + 4) {
      margin-top: 50px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: -25px;
        left: 0;
        background-color: #888da7;
      }
    }
  }
  ${theme.screens.smScreen} {
    &:nth-of-type(n + 2) {
      margin-top: 30px;
      position: relative;
      &:before {
        display: none;
      }
    }
  }
`;

const Logo = styled.img`
  margin-bottom: 25px;
  max-width: 147px;
`;

const MenuTitle = styled.div`
  font-size: 12px;
  color: ${theme.colors.light};
  margin-bottom: 27px;
  margin-top: 2px;
`;

const MenuTitleLink = styled(Link)`
  font-size: 12px;
  color: ${theme.colors.light};
  margin-bottom: 27px;
  transition: all 0.25s ease-in-out;
  display: inline-block;

  ${theme.mixins.onEvent} {
    color: ${theme.colors.light};
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const Address = styled.address`
  display: flex;
  flex-direction: column;
  ${theme.screens.mdScreen} {
    flex-direction: row;
  }
  ${theme.screens.smScreen} {
    width: 100%;
  }
  div {
    color: #f8faff;
    font-size: 12px;
    font-weight: $light;
    &:nth-of-type(2) {
      margin-top: 20px;
    }
    ${theme.screens.smScreen} {
      &:nth-of-type(2) {
        margin-top: unset;
        padding-left: 24px;
      }
    }
    @media (max-width: 320px) {
      &:nth-of-type(2) {
        margin-top: 20px;
        padding-left: 12px;
      }
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
`;

const MenuLink = styled(Link)`
  color: ${theme.colors.light};
  opacity: 0.5;
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 12px;

  ${theme.mixins.onEvent} {
    color: #fff;
    opacity: 1;
    text-decoration: underline;
  }
`;

const CopyRow = styled.div`
  background-color: #101840;
  padding: 35px 0;
  margin-top: 50px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .row {
      width: 100%;
    }
    ${theme.screens.smScreen} {
      flex-direction: column;
    }
  }
`;

const SocialIcon = styled.a`
  &:first-of-type {
    margin-right: 20px;
  }
  img {
    max-width: 15px;
  }
`;

const SocialLogo = styled.img`
  opacity: 0.25;
  transition: all 0.25s ease-in-out;
  ${theme.mixins.onEvent} {
    opacity: 0.75;
  }
`;

const PrivaciesHolder = styled.div`
  display: flex;
  column-gap: 30px;
  ${theme.screens.lgScreen} {
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const CopyLink = styled(Link)`
  font-size: 12px;
  color: ${theme.colors.gray};

  ${theme.mixins.onEvent} {
    color: #fff;
    text-decoration: underline;
  }
`;

const CopyHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
  ${theme.screens.lgScreen} {
    justify-content: center;
    margin-top: 30px;
  }
`;

const CopyText = styled.div`
  color: ${theme.colors.gray};
  text-align: right;

  ${theme.screens.lgScreen} {
    text-align: center;
  }

  &:last-of-type {
    ${theme.screens.lgScreen} {
      margin-top: 10px;
    }
  }
`;

const AdreamLogo = styled.img`
  display: inline-block;
  width: 72px;
  height: auto;
  margin-top: -12px;
  margin-left: 4px;
  transition: all 0.25s ease-in-out;
  position: relative;

  ${theme.mixins.onEvent} {
    opacity: 0.75;
  }

  ${theme.screens.smScreen} {
    margin-left: 4px;
    margin-top: -10px;
  }
`;

const Footer = ({ data }) => {
  // const caseStudies = data.allWpCaseStudy.nodes;
  const currentYear = new Date().getFullYear();
  return (
    <FooterMain>
      <ScrollToTop showBelow={250} />
      <div className="container">
        <div className="row justify-content-center">
          <FooterCol className="col-lg-2 col-md-4 col-sm-4 col-12">
            <Logo src={require("assets/images/logo.svg").default} alt="" />
            <MenuTitle>Company details:</MenuTitle>
            <Address>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-xs-6 col-sm-12">
                  Prodinity Limited <br />
                  134 Buckingham Palace Road, <br />
                  London, SW1W 9SA <br />
                  United Kingdom <br />
                </div>
                <div className="col-xs-6 col-sm-12">
                  Prodinity Sp. z o.o. <br />
                  ul. Kazimierza Wielkiego 58/6
                  <br />
                  30-074 Kraków <br />
                  Poland
                </div>
              </div>
            </Address>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/solutions/">Solutions</MenuTitleLink>

            <Menu>
              <MenuLink to="/business-insights/">Business Insights</MenuLink>
              <MenuLink to="/insurance-intermediaries/">
                Insurance Intermediaries
              </MenuLink>
              <MenuLink to="/data-pooling/">Data Pooling</MenuLink>
              <MenuLink to="/cyber-security/">Cyber Security</MenuLink>
              <MenuLink to="/budgeting/">Budgeting</MenuLink>
              <MenuLink to="/forecasting/">Forecasting</MenuLink>
              <MenuLink to="/reporting/">Reporting</MenuLink>
              <MenuLink to="/analysis/">Analysis</MenuLink>
              <MenuLink to="/other-services/">Other Services</MenuLink>
            </Menu>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/benefits/">Benefits</MenuTitleLink>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/industries/">Industries</MenuTitleLink>
          </FooterCol>
          {/* <div className="col-md-1">
            <MenuTitleLink to="/case-studies/">Case studies</MenuTitleLink>
            <Menu>
              {caseStudies?.map((nodes, index) => {
                const item = nodes;
                return <MenuLink key={index}>title={item.title}</MenuLink>;
              })}
            </Menu>
          </div> */}
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitle>Company</MenuTitle>
            <Menu>
              <MenuLink to="/history/">History</MenuLink>
              <MenuLink to="/mission-vision/">Mission & Vision</MenuLink>
              <MenuLink to="/our-team/">Our team</MenuLink>
              <MenuLink to="/careers/">Careers</MenuLink>
              <MenuLink to="/media-and-press-materials/">
                Media and press materials
              </MenuLink>
            </Menu>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/news/">News</MenuTitleLink>
            <Menu>
              <MenuLink to="/blog/">Blog</MenuLink>
            </Menu>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/faq/">FAQ</MenuTitleLink>
          </FooterCol>
          <FooterCol className="col-lg-1 col-md-2 col-sm-4 col-6">
            <MenuTitleLink to="/contact/">Contact</MenuTitleLink>
          </FooterCol>
          <FooterCol className="col-lg-2 col-md-2 col-sm-4 col-6">
            <MenuTitle>Follow us:</MenuTitle>
            <div>
              <SocialIcon
                href="https://www.linkedin.com/company/prodinity-limited"
                target="_blank"
                rel="noreferrer"
              >
                <SocialLogo
                  src={require("assets/icons/linkedin-gray.svg").default}
                  alt=""
                />
              </SocialIcon>
              <SocialIcon
                href="https://www.facebook.com/prodinity"
                target="_blank"
                rel="noreferrer"
              >
                <SocialLogo
                  src={require("assets/icons/facebook-gray.svg").default}
                  alt=""
                />
              </SocialIcon>
            </div>
          </FooterCol>
        </div>
      </div>
      <CopyRow>
        <div className="container">
          <div className="row">
            <PrivaciesHolder className="col-lg-4">
              <div className="row justify-content-lg-start justify-content-center">
                <CopyLink
                  to="/privacy-policy/"
                  className="col-auto footer-link"
                >
                  Privacy Policy
                </CopyLink>
                <CopyLink
                  to="/cookies-policy/"
                  className="col-auto footer-link"
                >
                  Cookies Policy
                </CopyLink>
              </div>
            </PrivaciesHolder>
            <CopyHolder className="col-lg-8">
              <div className="row justify-content-lg-end">
                <CopyText className="col-lg-auto footer-link">
                  © {currentYear} Prodinity. All rights reserved
                </CopyText>
                <CopyText className="col-lg-auto footer-link">
                  Proudly designed by
                  <a
                    href="https://adream.pl/en/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <AdreamLogo
                      src={
                        require("../../assets/images/logo-adream.png").default
                      }
                      alt=""
                    />
                  </a>
                </CopyText>
              </div>
            </CopyHolder>
          </div>
        </div>
      </CopyRow>
    </FooterMain>
  );
};

// export const caseStudies = () => {
//   return (
//     <StaticQuery
//       query={graphql`
//         query {
//           allWpCaseStudy(limit: 5) {
//             nodes {
//               slug
//               seo {
//                 metaDesc
//                 title
//               }
//               title
//               uri
//               language {
//                 name
//                 locale
//                 code
//                 slug
//               }
//             }
//           }
//         }
//       `}
//       render={data}
//     />
//   );
// };

// export const query = graphql`
//   query {
//     allWpCaseStudy(limit: 5) {
//       nodes {
//         slug
//         seo {
//           metaDesc
//           title
//         }
//         title
//         uri
//         language {
//           name
//           locale
//           code
//           slug
//         }
//       }
//     }
//   }
// `;

export default Footer;
