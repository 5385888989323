import React, { useState } from "react";
import styled from "styled-components";
import theme from "theme";

const HamburgerLine = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: ${theme.colors.pink};
  transform: rotate(0deg);
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
  &:nth-child(1) {
    top: 0px;
  }
  &:nth-child(2),
  &:nth-child(3) {
    top: 10px;
  }
  &:nth-child(4) {
    top: 20px;
    right: 0;
    left: unset;
  }
`;

const HamburgerButton = styled.button`
  width: 27px;
  height: 15px;
  transform: translate(0, -50%);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 700;
  display: none;
  background-color: transparent;
  ${theme.screens.lgScreen} {
    display: block;
  }
  &:focus {
    outline: none;
  }

  /* &:not(.is-active):active ${HamburgerLine} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      transform: rotate(0deg);
      animation: none;
    }
  } */
  ${({ active }) =>
    active &&
    `
    ${HamburgerLine} {
        &:nth-child(1) {
            top: 13px;
            width: 0%;
            left: 50%;
        }
        &:nth-child(2) {
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
        }
        &:nth-child(4) {
            opacity: 0
        }
    }
  `}
`;

const Hamburger = ({ onClick }) => {
  const [active, setActive] = useState(false);
  return (
    <HamburgerButton
      type="button"
      aria-label="Open Menu"
      onClick={(e) => {
        onClick();
        setActive(!active);
      }}
      active={active}
      // className={`hamburger hamburger--squeeze ${
      //   isMenuOpen ? "is-active" : ""
      // }`}
      // onClick={(e) => setIsMenuOpen(!isMenuOpen)}
    >
      {Array(4)
        .fill(null)
        .map((item, index) => (
          <HamburgerLine key={index} />
        ))}
    </HamburgerButton>
  );
};

export default Hamburger;
